import { StatsD } from 'hot-shots';
import { IMetricsClient } from 'dd-server/site/metrics/types';
import { logger } from 'dd-server/site/utils/logger/winston';
import { getConfig } from 'dd-client/site/config/utils/config';

const SITE_NAME = getConfig('siteName');

function MetricsClient(): IMetricsClient {
  const isEnabled = getConfig('metrics.enabled');
  let instance: StatsD | undefined;

  function getInstance(): StatsD {
    if (!instance) {
      instance = new StatsD({
        errorHandler: (error) => {
          logger.error(`Socket errors caught here: ${error.message}`);
        },
        globalTags: {
          app_env: getConfig('env'),
          app_name: getConfig('appName'),
          app_site: SITE_NAME,
        },

        host: process.env.DD_AGENT_HOST,

        port: parseInt(process.env.DD_AGENT_PORT as string, 10),

        prefix: 'ssr.',
        // @ts-ignore
        protocol: process.env.DD_AGENT_PROTOCOL,
        sampleRate: parseInt(process.env.DD_SAMPLE_RATE as string, 10),
      });
    }

    return instance;
  }

  function increment(name: string, tags: Array<string> = []): void {
    if (!isEnabled) {
      return;
    }

    getInstance().increment(name, tags);
  }

  function reportErrorEvent(message: string): void {
    if (!isEnabled) {
      return;
    }

    getInstance().event(
      'error',
      message,
      {
        alert_type: 'error',
      },
    );
  }

  function timing(name: string, value: number): void {
    if (!isEnabled) {
      return;
    }

    getInstance().timing(name, value);
  }

  return {
    increment,
    reportErrorEvent,
    timing,
  };
}

const metrics = MetricsClient();

export {
  metrics,
};
